import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PromptListApi } from '../../app-api/api';
import isBrowser from '../../util/gatsby';

export const fetchPromptListAsync = createAsyncThunk(
  'prompt/PromptListApi',
  async (obj) => {
    const response = await PromptListApi(obj);
    return response;
  }
);

const initialState = {
  promptList: [],
  loadMore: 1,
};

export const PromptsSlice = createSlice({
  name: 'prompt',
  initialState,
  extraReducers: {
    [fetchPromptListAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchPromptListAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action.payload) {
        state.promptList = [
          ...state.promptList,
          ...action.payload.memory_prompt_data_detail,
        ];
        state.loadMore = action.payload.load_more;
      } else {
        if (isBrowser && process.env.NODE_ENV !== 'test') {
          localStorage.clear();
          window.location?.reload();
        }
      }
    },
    [fetchPromptListAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const selectPromptList = (state) => state?.prompt?.promptList;
export const selectLoadMore = (state) => state?.prompt?.loadMore;
export const selectPromptCategories = (state) => state?.prompt?.categories;

export default PromptsSlice.reducer;
