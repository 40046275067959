import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetMemory,
  GetMemoryData,
  handleLike,
  handleUnLike,
  MemoryActions,
  MyDraftsApi,
  PublishMemory,
  RecentPublishedApi,
} from '../../app-api/api';
import { dataWithoutHtml, introductoryData } from '../../app-api/dummyData';
import {
  CreateMemory,
  EditMemory,
  GetMemories,
  GetSingleMemory,
} from '../../app-api/memoryService/MemoryApi';
import {
  PublishMemoryObject,
  draftObject,
  draftResponse,
  recentObject,
  recentResponse,
  singleMemoryObject,
  singleMemoryResponse,
  getMemoryResponse,
  getMemoryObject,
  draftMemoryObject,
  draftMemoryResponse,
} from '../../util/Format';
import Image from '../../images/hello-expandedMemory.jpg';

export const fetchMemoryAsync = createAsyncThunk(
  'memory/recentPublishedApis',
  async (obj) => {
    const response = await RecentPublishedApi(obj);
    return response;
  }
);

export const fetchEditAsync = createAsyncThunk(
  'memory/EditPublishedApi',
  async (obj) => {
    const data =
      process.env.GATSBY_NEW_API !== 'FALSE' ? obj : draftObject(obj);
    const response =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? await GetMemories(data)
        : await MyDraftsApi(data);
    const result =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? response
        : draftResponse(response);
    return result;

    // async (obj) => {
    //   const response = await MyDraftsApi(obj);
    //   return response;
  }
);

export const fetchCurrentUserMemories = createAsyncThunk(
  'memory/recentPublishedApi',
  async (obj) => {
    const data =
      process.env.GATSBY_NEW_API !== 'FALSE' ? obj : recentObject(obj);
    const response =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? await GetMemories(data)
        : await RecentPublishedApi(data);
    const result =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? response
        : recentResponse(response);
    return result;
  }
);

export const getSingleMemory = createAsyncThunk(
  'memory/getMemory',
  async (obj) => {
    const payload =
      process.env.GATSBY_NEW_API !== 'FALSE' ? obj : singleMemoryObject(obj);
    const response =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? await GetSingleMemory(payload)
        : await GetMemory(payload);
    const result =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? response
        : singleMemoryResponse(response);
    return result;
  }
);

export const getMemoryDataAsync = createAsyncThunk(
  'memory/getMemoryData',
  async (obj) => {
    const payload =
      process.env.GATSBY_NEW_API !== 'FALSE' ? obj : getMemoryObject(obj);
    const response =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? await GetSingleMemory(payload)
        : await GetMemoryData(payload);
    const result =
      process.env.GATSBY_NEW_API !== 'FALSE'
        ? response
        : getMemoryResponse(response);
    return result;
  }
);

export const fetchTimelineAsync = createAsyncThunk(
  'timeline/recentPublishedApi',
  async (obj) => {
    const response = await RecentPublishedApi(obj);
    return response;
  }
);

export const LikeMemoryAsync = createAsyncThunk(
  'memory/handleLike',
  async (obj) => {
    const response = await handleLike(obj);
    return response;
  }
);
export const UnLikeMemoryAsync = createAsyncThunk(
  'memory/handleUnLike',
  async (obj) => {
    const response = await handleUnLike(obj);
    return response;
  }
);
export const CreateMemoryAsync = createAsyncThunk(
  'memory/createMemory',
  async (obj) => {
    if (process.env.GATSBY_NEW_API !== 'FALSE' && obj.data.title === '') {
      return null;
    } else {
      const payload =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? obj.data
          : PublishMemoryObject(obj);
      const response =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? obj.status === 'new'
            ? await CreateMemory(payload)
            : await EditMemory(payload, obj.nid)
          : await PublishMemory(payload);
      return response;
    }
  }
);

export const DraftMemoryAsync = createAsyncThunk(
  'memory/draftMemory',
  async (obj) => {
    if (obj.status === 'new' || process.env.GATSBY_NEW_API !== 'FALSE') {
      const payload =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? obj.data
          : draftMemoryObject(obj);
      const response =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? obj.status === 'new'
            ? await CreateMemory(payload)
            : await EditMemory(payload, obj.nid)
          : await PublishMemory(payload);
      const result =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? response
          : draftMemoryResponse(response);
      return result;
    } else {
      const newObj = {
        configurationTimestamp: '1536928630',
        details: {
          id: obj.nid,
          type: 'my_stories',
          action_type: 'move_to_draft',
        },
      };
      const result = await MemoryActions(newObj);
      return result;
    }
  }
);
export const DeleteMemoryAsync = createAsyncThunk(
  'memory/deleteMemory',
  async (obj) => {
    if (process.env.GATSBY_NEW_API !== 'FALSE') {
      return;
    }
    const response = await MemoryActions(obj);
    return response;
  }
);
const initialState = {
  data: null,
  allMemories: [],
  selectedData: [],
  memoryData: {},
  myMemories: [],
  totalMemories: 0,
  singleMemoryData: [],
  singleMemoryStatus: 'idle',
  timelineData: [],
  dummyData: [],
  prompts: [],
  opacity: 1,
  status: '',
  page: 0,
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  filters: {
    All: 1,
    Me: 1,
    'My Friends': 1,
    'Non Friends': 1,
    'Close Friends': 1,
    CB: 1,
    year: new Date().getFullYear(),
    yearFrom: 2016,
    yearTo: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    monthNum: 0,
    Music: 1,
    Sports: 1,
    Tv: 1,
    News: 1,
    Movies: 1,
    Books: 1,
  },
  monthCount: [
    { month: new Date().getMonth() + 1, year: new Date().getFullYear() },
  ],
  count: [1],
  memoryCount: 0,
  yearCount: [{ count: 0, year: new Date().getFullYear() }],
  filterYearError: '',
  CreateMemoryResponse: {},
  hasMoreMemory: true,
  currentContextMenu: -1,
  EditPageStatus: 'idle',
};
export const MemorySlice = createSlice({
  name: 'memory',
  initialState,
  reducers: {
    addFilter: (state, action) => {
      Object.keys(action.payload).map((item) => {
        if (action.payload[item] % 2 !== 0) {
          state.filters[item] = 1;
          return null;
        } else {
          state.filters[item] = 0;
          return null;
        }
      });
    },

    EmptyTimelineData: (state) => {
      state.timelineData.length = 0;
    },
    EmptyMemoryData: (state) => {
      state.memoryData = {};
    },
    pageCounter: (state) => {
      state.page += 1;
    },
    setYearFilter: (state, action) => {
      state.filters.yearFrom = action.payload.yearFrom;
      state.filters.yearTo = action.payload.yearTo;
      state.filters.year = action.payload.yearTo;
      state.filters.month = 1;
      state.yearCount = [{ count: 0, year: state.filters.yearTo }];
    },

    setFilterYearError: (state, action) => {
      state.filterYearError = action?.payload?.Error;
    },
    setJumpFilter: (state, action) => {
      state.timelineData.length = 0;
      state.filters.month = action?.payload?.month;
      state.filters.year = action?.payload?.year;
      state.monthCount = [
        { month: state?.filters?.month, year: state?.filters?.year },
      ];
    },
    setMonthYear: (state, action) => {
      state.month = action?.payload?.month;
      state.year = action?.payload?.year;
    },
    setYearCount: (state, action) => {
      state.yearCount.push(action.payload);
    },
    setMonthCount: (state, action) => {
      state.monthCount.push(action.payload);
    },
    getPreviewData: (state, { payload }) => {
      const { Nid } = payload;
      const filteredData = state.data.filter((item) => item.nid === Nid);
      state.selectedData = filteredData;
    },
    setPreviewData: (state) => {
      state.selectedData = [];
    },
    setMobileHeaderOpacity: (state, { payload }) => {
      const { Opacity } = payload;
      state.opacity = Opacity;
    },
    clearMemory: (state) => {
      state.allMemories = [];
    },
    setHasMoreMemory: (state, { payload }) => {
      state.hasMoreMemory = payload;
    },
    setCurrentContextMenu: (state, { payload }) => {
      state.currentContextMenu = payload.id;
    },
    resetMemories: (state) => {
      state.myMemories = [];
    },
    resetPagination: (state) => {
      state.page = 0;
    },
    setIntroductoryData: (state, action) => {
      if (action.payload === 'memoryDetail') {
        state.singleMemoryData = {
          title: 'Welcome to My Stories Matter!',
          body: {
            content: introductoryData,
          },
          images: [Image],
        };
      } else {
        state.myMemories.unshift({
          title: 'Welcome to My Stories Matter!',
          body: {
            content: dataWithoutHtml,
          },
          images: [{ thumbnail_large_url: Image }],
          introductory: true,
          memoryID: 0,
        });
        state.totalMemories = state.totalMemories + 1;
      }
    },
    deleteIntroductoryMemory: (state) => {
      state.myMemories.shift();
      state.totalMemories = state.totalMemories - 1;
    },
    changeDeleteStatus: (state) => {
      state.isDeleted = true;
    },
  },
  extraReducers: {
    [fetchMemoryAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchMemoryAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action?.payload) {
        state.data = [...state?.data, ...action?.payload?.data];
        state.prompts = action?.payload?.active_prompts;
      } else {
        localStorage.clear();
        window.location.reload();
      }
    },
    [fetchMemoryAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchTimelineAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchTimelineAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action?.payload?.count !== 0) {
        state.timelineData = [
          ...state.timelineData,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];

        state.memoryCount += action?.payload?.count;
        state.count.push(state.memoryCount + 1);
        state.dummyData.push(
          action?.payload?.data[action?.payload?.data?.length - 1]
        );
      } else {
        state.timelineData = [...state.timelineData, ...state.dummyData];
        state.dummyData.length = 0;
      }
    },
    [fetchTimelineAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Edit api

    [fetchEditAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchEditAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action.payload) {
        state.allMemories = [
          ...state.allMemories,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];
      }
    },
    [fetchEditAsync.rejected]: (state) => {
      state.status = 'failed';
    },
    [fetchCurrentUserMemories.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCurrentUserMemories.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.totalMemories = action.payload.page.totalItems;
        state.myMemories = [
          ...state?.myMemories,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];
      } else {
        localStorage.clear();
        window.location.reload();
      }
    },
    [fetchCurrentUserMemories.rejected]: (state, action) => {
      state.status = 'failed';
      localStorage.clear();
      window.location.reload();
      state.error = action.payload;
    },

    [getSingleMemory.pending]: (state) => {
      state.singleMemoryStatus = 'loading';
    },
    [getSingleMemory.fulfilled]: (state, action) => {
      state.singleMemoryStatus = 'idle';
      state.singleMemoryData =
        process.env.GATSBY_NEW_API !== 'FALSE'
          ? action.payload.data[0]
          : action.payload;
    },
    [getSingleMemory.rejected]: (state, action) => {
      state.singleMemoryStatus = 'failed';
      state.error = action.payload;
    },
    [CreateMemoryAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [CreateMemoryAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.CreateMemoryResponse = action.payload;
    },
    [CreateMemoryAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [getMemoryDataAsync.pending]: (state) => {
      state.singleMemoryStatus = 'loading';
    },
    [getMemoryDataAsync.fulfilled]: (state, action) => {
      state.singleMemoryStatus = 'idle';
      state.memoryData = action.payload.data[0];
    },
    [getMemoryDataAsync.rejected]: (state, action) => {
      state.singleMemoryStatus = 'failed';
      state.error = action.payload;
    },
     },
});

export const {
  addFilter,
  changeDeleteStatus,
  setMonthYear,
  setMonthCount,
  setYearCount,
  pageCounter,
  setYearFilter,
  setJumpFilter,
  setFilterYearError,
  EmptyTimelineData,
  EmptyMemoryData,
  getPreviewData,
  setPreviewData,
  setMobileHeaderOpacity,
  clearMemory,
  setCurrentContextMenu,
  resetMemories,
  resetPagination,
  setIntroductoryData,
  deleteIntroductoryMemory,
} = MemorySlice.actions;
export const selectData = (state) => state?.memory?.data;
export const selectTimelineData = (state) => state?.memory?.timelineData;
export const selectPrompts = (state) => state?.memory?.prompts;
export const selectFilters = (state) => state?.memory?.filters;
export const selectPage = (state) => state?.memory?.page;
export const selectError = (state) => state?.filterYearError;
export const selectMonth = (state) => state?.memory?.month;
export const selectYear = (state) => state?.memory?.year;
export const selectCount = (state) => state?.memory?.count;
export const selectYearCount = (state) => state?.memory?.yearCount;
export const selectMemoryCount = (state) => state?.memory?.memoryCount;
export const selectMonthCount = (state) => state?.memory?.monthCount;
export const SelectedOpacity = (state) => state?.memory?.opacity;
export const selectMyMemories = (state) => state?.memory?.myMemories;
export const selectTotalMemories = (state) => state?.memory?.totalMemories;
export const selectSingleMemoryData = (state) =>
  state?.memory?.singleMemoryData;
export const selectSingleMemoryStatus = (state) =>
  state?.memory?.singleMemoryStatus;
export const selectCreateMemoryResponse = (state) =>
  state.memory.CreateMemoryResponse;
export const hasMoreMemory = (state) => state.memory.hasMoreMemory;
export const selectCurrentContextMenu = (state) =>
  state.memory.currentContextMenu;
export const selectStatus = (state) => state?.memory?.status;

export const selectMemoryData = (state) => state.memory.memoryData;
export const selectAllMemories = (state) => state.memory.allMemories;
export default MemorySlice.reducer;
