import axiosInstance from './axiosUserApi';

export const SignInApi = (data) =>
  axiosInstance.post('api/v1/users/authentication/authenticate', data);

export const FederatedSignIn = (data) =>
  axiosInstance.post('api/v1/users/authentication/add-cognito-user', data);

export const InitiateForgotPassword = (email) =>
  axiosInstance.post('api/v1/users/authentication/forgot-password', { email });

export const ResetPasswordApi = (obj) =>
  axiosInstance.put('api/v1/users/authentication/confirm-password', obj);

export const UpdateUserInfo = (obj) => {
  //   const userId = localStorage.getItem('uid');
  return axiosInstance.patch('api/v1/users/', obj.data);
};

export const GetUserInfo = () => {
  return axiosInstance.get('api/v1/users/');
};

export const ChangeUserPassword = (obj) => {
  // eslint-disable-next-line no-unused-vars
  const { email, oldPassword, newPassword } = obj;
  return axiosInstance.put('api/v1/users/authentication/change-password', obj);
};
export const Register = (data) =>
  axiosInstance.post('api/v1/users/authentication/register', data);

export const VerifyAccount = (obj) =>
  axiosInstance.patch(`api/v1/users/admin/confirm-user/${obj.id} `, obj.data); 

export const UpdateUserConfig = (obj) =>
  axiosInstance.patch('api/v1/users/config', obj.data);

export const ResentActivationEmail = (obj) =>
  axiosInstance.post('api/v1/users/authentication/resend-email', obj);
