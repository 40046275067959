export const loginObject = (obj) => {
  // this function will convert new api payload format into old api format
  const newObj = {
    emailId: obj.email,
    password: obj.password,
  };
  return newObj;
};
export const loginResponse = (oldResponse) => {
  // this function converts old api response into new API response
  const newResponse = {
    code: oldResponse.ResponseCode,
    message: oldResponse.ResponseMessage,
    data: {
      uid: oldResponse.Response?.UserID,
      auth_time: Math.floor(new Date().getTime() / 1000),
      email: oldResponse.Response?.email ? oldResponse.Response.email : '',
      firstName: oldResponse.Response?.FirstName,
      lastName: oldResponse.Response?.LastName,
      fullName:
        oldResponse.Response?.FirstName + ' ' + oldResponse.Response?.LastName,
      exp: Math.floor(new Date().getTime() / 1000) + 24,
      token: {
        accessToken: oldResponse.Response?.UserAuthToken,
        idToken: oldResponse.Response?.UserAuthToken,
        refreshToken: oldResponse.Response?.UserAuthToken,
      },
      config: {
        introductoryData: false,
      },
    },
  };
  return newResponse;
};

export const recentObject = (obj) => {
  const newObj = {
    type: 'feed',
    filter: {
      mystories: {
        me: 1,
        friend: 0,
        others: 0,
        groups: {
          1: 0,
          82: 0,
          136: 0,
          148: 0,
        },
      },
      save_filters: 0,
    },
    configurationTimestamp: '0',
    searchTerm: {
      prompt_pagination: 0,
      start: obj.params?.pageIndex,
      length: obj.params?.pageSize,
      searchString: obj.params?.searchTerm,
      last_memory_date: '',
      request_type: 'older',
    },
    randomPrompts: 0,
  };
  return newObj;
};

export const recentResponse = (obj) => {
  const dataArr = obj?.Details;
  if (dataArr) {
    dataArr.data.map((item) => {
      item.memoryID = item.nid;
      item.author = {
        name: { first: '', last: '' },
        profilePictures: { upload: '' },
      };
      item.author.name.first = item.user_details.field_first_name_value;
      item.author.name.last = item.user_details.field_last_name_value;
      item.author.profilePictures.upload = item.user_details.thumbnail_preview;
      item.imageURLs = [];
      item.imageURLs.push = item.images ? item.images[0].url : '';
      item.body = { content: '' };
      item.body.content = item.description;
      item.date = { updated: '' };
      item.date.updated = item.updated;
    });
  }
  const newResponse = {
    message: obj.ResponseMessage,
    code: obj.ResponseCode,
    page: {
      totalItems: dataArr.count,
      totalPages: 'NA',
      currentPage: 'NA',
      pageSize: 'NA',
    },
    data: dataArr.data,
  };
  return newResponse;
};

export const singleMemoryObject = (id) => {
  const newObj = {
    configurationTimestamp: '0',
    memoryDetails: {
      nid: id,
      type: 'my_stories',
    },
  };
  return newObj;
};
export const singleMemoryResponse = (obj) => {
  const collabArray = [];
  obj.collaborators.map((item, index) => {
    collabArray[index] = {
      name: {
        first: item.field_first_name_value,
        last: item.field_last_name_value,
      },
    };
  });
  const newObj = {
    title: obj.title,
    collaborators: collabArray,
    author: {
      name: {
        first: obj.user_details.field_first_name_value,
        last: obj.user_details.field_last_name_value,
      },
      profilePictures: {
        upload: obj.user_details.uri,
      },
    },
    location: {
      name: obj.location,
    },
    body: {
      content: obj.description,
    },
    date: {
      created: obj.created,
      occurred: obj.memory_date,
    },
    images: obj.images,
    prompts: obj.prompts,
    challenge_name: obj.challenge_name,
  };
  return newObj;
};

export const SignUpObject = (obj) => {
  const newObj = {
    authorizationInfo: {
      emailAddress: obj.email,
      password: obj.password,
    },
    personalInfo: {
      field_first_name: {
        module: 'text',
        type: 'text_textfield',
        value: obj.firstName,
      },
      field_last_name: {
        module: 'text',
        type: 'text_textfield',
        value: obj.lastName,
      },
      field_registration_date: {
        value: obj.birthYear,
        module: 'date',
        type: 'date_select',
      },
    },
    configurationTimestamp: '1650446484',
  };
  return newObj;
};

export const SignUpResponse = (obj) => {};

export const PublishMemoryObject = (obj) => {
  const visibility = {
    1: 'only_me',
    2: 'custom',
    3: 'all',
    4: 'cueback',
  };

  const newObj = {
    details: {
      title: obj.data.title,
      memory_date: {
        year: obj.data.date ? obj.data.date?.split('-')[0].toString() : '',
        month: obj.data.date
          ? (obj.data.date?.split('-')[1] * 1).toString()
          : '',
        day: obj.data.date ? (obj.data.date?.split('-')[2] * 1).toString() : '',
      },
      nid: obj.nid,
      share_option: visibility[obj.data.visibility],
      action: 'publish',
    },
  };
  return newObj;
};

export const PublishMemoryResponse = (obj) => {
  const newObj = {
    code: obj.ResponseCode,
    message: obj.ResponseMessage,
    data: [
      {
        memoryID: obj.Status,
      },
    ],
    etherpad_url: obj.etherpad_url,
    padid: obj.padid,
    sessionId: obj.sessionId,
  };
  return newObj;
};

export const draftMemoryObject = (obj) => {
  const newObj = {
    details: {
      title: obj.data.title,
      memory_date: {
        year: obj.data.date ? obj.data.date?.split('-')[0].toString() : '',
        month: obj.data.date
          ? (obj.data.date?.split('-')[1] * 1).toString()
          : '',
        day: obj.data.date ? (obj.data.date?.split('-')[2] * 1).toString() : '',
      },
      // nid: obj.nid,
      //share_option: visibility[obj.data.visibility],
      // action:'publish'
    },
  };
  return newObj;
};

export const draftMemoryResponse = (obj) => {
  const newObj = {
    code: obj.ResponseCode,
    message: obj.ResponseMessage,
    // data: [{
    //   memoryID: obj.Status
    // }],
    // etherpad_url: obj.etherpad_url,
    // padid: obj.padid,
    // sessionId: obj.sessionId
  };
  return newObj;
};
export const getMemoryObject = (id) => {
  const newObj = {
    configurationTimestamp: '0',
    details: {
      nid: id,
    },
  };
  return newObj;
};
export const getMemoryResponse = (obj) => {
  const newObj = {
    data: [
      {
        title: obj.Data.title,
        location: {
          name: obj.Data.location,
        },
        body: {
          content: obj.Data.description,
        },
        date: {
          created: obj.Data.created,
          occurred: obj.Data.memory_date,
        },
        prompts: obj.Data.prompts,
        challenge_name: obj.Data.challenge_name,
        images: obj.Data.images,
        etherpad_details: obj.Data.etherpad_details,
      },
    ],
  };

  return newObj;
};
export const draftObject = (obj) => {
  const newObj = {
    details: {
      type: 'mine',
      mine_type: 'all',
      offset: obj.params.pageIndex,
      limit: obj.params.pageSize,
    },
  };
  return newObj;
};

export const draftResponse = (obj) => {
  const dataArr = obj.Data;
  if (dataArr) {
    dataArr.data.map((item) => {
      item.memoryID = item.nid;
      item.author = {
        name: { first: '', last: '' },
        profilePictures: { upload: '' },
      };
      item.author.name.first = item.user_details.field_first_name_value;
      item.author.name.last = item.user_details.field_last_name_value;
      item.author.profilePictures.upload = item.user_details.thumbnail_preview;
      item.imageURLs = [];
      item.imageURLs.push = item.images ? item.images[0].url : '';
      item.body = { content: '' };
      item.body.content = item.description;
      item.date = { updated: '' };
      item.date.updated = item.changed;
      return item;
    });
  }

  const newObj = {
    message: obj.ResponseMessage,
    code: obj.ResponseCode,
    data: dataArr.data,
  };
  return newObj;
};
