import axiosInstance from './axiosApi';

export const LoginApi = (data) =>
  axiosInstance.post('alumni/login', data).then((res) => res);
export const Logout = () => axiosInstance.post('alumni/logout');

export const RecentPublishedApi = (data) =>
  axiosInstance.post('timeline/list', data).then((res) => res);
// axiosInstance.post('timeline/list', data).then((res) => res.Details);

export const MyDraftsApi = (data) =>
  axiosInstance.post('memory_draft/get', data).then((res) => res);

export const getEditApi = (data) =>
  axiosInstance.post('timeline/list', data).then((res) => {
    return res.Details;
  });

export const GetComments = (obj) =>
  axiosInstance
    .post('get/comments', obj)
    .then((res) => res.commentList.comments);

export const handleLike = (data) => axiosInstance.post('actions/like', data);
export const handleUnLike = (data) =>
  axiosInstance.post('actions/unlike', data);

export const AddComment = (obj) => axiosInstance.post('actions/comment', obj);

export const CollectionListing = (obj) =>
  axiosInstance.post('collection/list', obj);

export const FriendsList = (obj) =>
  axiosInstance.post('get/users', obj).then((res) => res.UserList);

export const fetchTags = (obj) =>
  axiosInstance.post('get/memory_tags', obj).then((res) => res.MemoryTags);

export const PublishMemory = (obj) => {
  return axiosInstance.post('mystory/create_update', obj);
};
export const GetMemoryData = (obj) =>
  axiosInstance.post('mystory/edit_memory_values', obj);

export const UploadFile = (obj) =>
  axiosInstance.post('mystory/file_upload', obj);

export const MemoryActions = (obj) => axiosInstance.post('actions/memory', obj);

export const SsoLogin = (obj) => axiosInstance.post('alumni/ssologin', obj);

export const ResetPasswordApi = (obj) =>
  axiosInstance.post('alumni/forgot_password', obj);

export const RegisterApi = (obj) => axiosInstance.post('alumni/register', obj);

export const Check = () =>
  axiosInstance.post('configurations/registration_form_details');

export const ProfileInfo = () =>
  axiosInstance.post('alumni/profile_page_details', {
    configurationTimestamp: '1536928630',
  });

export const MindPopListApi = (obj) =>
  axiosInstance
    .post('mindpop/list', obj)
    .then((res) => res.Details.mindPopList);

export const AddMindPop = (obj) => axiosInstance.post('mindpop/update', obj);

export const PromptListApi = (obj) =>
  axiosInstance.post('prompts/list', obj).then((res) => res.Details);

export const SetWelcomePopupStatus = () =>
  axiosInstance.post('configurations/welcome_popup_visit', { type: 'set' });

export const GetWelcomePopupStatus = () =>
  axiosInstance.post('configurations/welcome_popup_visit', { type: 'get' });
export const ChangePasswordApi = (obj) =>
  axiosInstance.post('alumni/change_password', obj).then((res) => res);
export const GetMemory = (obj) =>
  axiosInstance.post('timeline/get_memory', obj).then((res) => res.Details);

export const UpdateUserDetails = (obj) =>
  axiosInstance.post('alumni/update', obj);
