/* eslint-disable max-len */
import axios from 'axios';
import isBrowser from '../../util/gatsby';
import configData from '../../config/cognitoConfig';

const baseURL =
  process.env.GATSBY_VERCEL_RELEASE_1_2 === 'TRUE' ||
  process.env.GATSBY_AWS_ENV === 'PROD'
    ? 'https://memory.api.mystoriesmatter.com'
    : process.env.GATSBY_AWS_ENV === 'QA'
    ? 'https://qa-memory.api.mystoriesmatter.com'
    : 'https://dev-memory.api.mystoriesmatter.com';
const axiosInstance = axios.create({
  baseURL,
  headers: {
    platform: '2',
    apptype: '0',
    'Accept-Language': 'en',
  },
});

axiosInstance.interceptors.request.use((req) => {
  if (isBrowser) {
    req.headers['Authorization'] =
      localStorage.getItem('idToken') ||
      localStorage.getItem(
        'CognitoIdentityServiceProvider.'+ configData.clientId+'.' +
        localStorage.getItem(
           'CognitoIdentityServiceProvider.'+ configData.clientId+'.LastAuthUser'
        ) +
        '.idToken'
    );
    req.headers['refreshToken'] =
      localStorage.getItem('refreshToken') ||
      localStorage.getItem(
        'CognitoIdentityServiceProvider.'+ configData.clientId+'.' +
        localStorage.getItem(
           'CognitoIdentityServiceProvider.'+ configData.clientId+'.LastAuthUser'
        ) +
          '.refreshToken'
      );
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error('Error: ', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
