import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangePasswordApi,
  ProfileInfo,
  UpdateUserDetails,
} from '../../app-api/api';
import {
  UpdateUserConfig,
  UpdateUserInfo,
  GetUserInfo,
  ChangeUserPassword,
  InitiateForgotPassword,
  ResetPasswordApi,
} from '../../app-api/userService/UserApi';

export const fetchUserDetails = createAsyncThunk(
  'user/userDetails',
  async (obj, { fulfillWithValue, rejectWithValue }) => {
    try {
      const NEW_API = process.env.GATSBY_NEW_API !== 'FALSE';
      if (NEW_API) {
        const response = await GetUserInfo();
        if (response?.data?.length > 0) {
          const { name } = response.data[0];
          localStorage.setItem('fullName', `${name.first} ${name.last}`);
          return fulfillWithValue(response.data[0]);
        } else {
          return {};
        }
      } else {
        const response = await ProfileInfo(obj);
        return fulfillWithValue(response);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (obj, { fulfillWithValue, rejectWithValue }) => {
    const NEW_API = process.env.GATSBY_NEW_API !== 'FALSE';
    if (NEW_API) {
      try {
        const res = await ChangeUserPassword(obj);
        return fulfillWithValue(res);
      } catch (err) {
        return rejectWithValue(err);
      }
    } else {
      try {
        const res = await ChangePasswordApi(obj);
        return fulfillWithValue(res);
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  }
);

export const UpdateConfig = createAsyncThunk(
  'user/updateconfig',
  async (id) => {
    const response = await UpdateUserConfig(id);
    return response;
  }
);

export const InitiateForgotPasswordRequest = createAsyncThunk(
  'user/initiateForgotPassword',
  async (email, { rejectWithValue, fulfillWithValue }) =>
    InitiateForgotPassword(email)
      .then((res) => fulfillWithValue(res))
      .catch((err) => rejectWithValue(err))
);

export const ResetPasswordRequest = createAsyncThunk(
  'user/resetPassword',
  async (obj, { rejectWithValue, fulfillWithValue }) =>
    ResetPasswordApi(obj)
      .then((res) => fulfillWithValue(res))
      .catch((err) => rejectWithValue(err))
);
export const updateUserDetails = createAsyncThunk(
  'user/update',
  async (obj) => {
    try {
      const NEW_API = process.env.GATSBY_NEW_API !== 'FALSE';
      if (!NEW_API) {
        const request = {
          updateInfo: {
            field_first_name: {
              type: 'text_textfield',
              module: 'text',
              value: obj.data.firstName,
            },
            field_last_name: {
              type: 'text_textfield',
              module: 'text',
              value: obj.data.lastName,
            },
            field_birthday: {
              type: 'date_select',
              module: 'date',
              value: `${obj.data.birthYear}-01-01 00:00:00`,
            },
          },
          configurationTimestamp: '1536928630',
        };
        const response = await UpdateUserDetails(request);
        return response;
      } else {
        const response = await UpdateUserInfo(obj);
        if (response.code === 200) {
          localStorage.setItem(
            'fullName',
            obj.data.firstName + ' ' + obj.data.lastName
          );
        }
        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const initialState = {
  status: 'idle',
  UserDetails: [],
  ChangePasswordResponse: {},
  UpdateConfigResponse: {},
  ForgotPasswordResponse: {},
  UpdateUserDetailsResponse: '',
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [fetchUserDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.UserDetails = action.payload;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.status = 'failed';
      localStorage.clear();
      window.location.reload();
      state.error = action.payload;
    },
    [changePassword.pending]: (state) => {
      state.status = 'loading';
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ChangePasswordResponse = action.payload;
    },
    [changePassword.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [UpdateConfig.pending]: (state) => {
      state.status = 'loading';
    },
    [UpdateConfig.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.UpdateConfigResponse = action.payload;
    },
    [UpdateConfig.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [InitiateForgotPasswordRequest.pending]: (state, action) => {
      state.status = 'loading';
    },
    [InitiateForgotPasswordRequest.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ForgotPasswordResponse = action.payload;
    },
    [InitiateForgotPasswordRequest.rejected]: (state, action) => {
      state.status = 'failed';
      state.ForgotPasswordResponse = action.payload;
    },
    [ResetPasswordRequest.pending]: (state, action) => {
      state.status = 'loading';
    },
    [ResetPasswordRequest.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ForgotPasswordResponse = action.payload;
    },
    [ResetPasswordRequest.rejected]: (state, action) => {
      state.status = 'failed';
      state.ForgotPasswordResponse = action.payload;
    },
    [changePassword.pending]: (state) => {
      state.status = 'loading';
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ChangePasswordResponse = action.payload;
    },
    [changePassword.rejected]: (state, action) => {
      state.status = 'failed';
      state.ChangePasswordResponse = action.payload;
    },
    [updateUserDetails.pending]: (state, action) => {
      state.status = 'loading';
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.UpdateUserDetailsResponse = action.payload.code;
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
  reducers: {
    clearChangePasswordResponse: (state, action) => {
      state.ChangePasswordResponse = {};
    },
  },
});

export const selectChangePasswordResponse = (state) =>
  state.user.ChangePasswordResponse;
export const selectUserDetails = (state) => state.user.UserDetails;
export const selectStatus = (state) => state.user.status;
export const selectResponse = (state) => state.user.UpdateUserDetailsResponse;
export const selectForgotPasswordResponse = (state) =>
  state.user.ForgotPasswordResponse;
export const { clearChangePasswordResponse } = UserSlice.actions;
export default UserSlice.reducer;
