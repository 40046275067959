/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Register, ResentActivationEmail, VerifyAccount } from '../../app-api/userService/UserApi';

export const SignUpAsync = createAsyncThunk('signup/registerApi', async (obj, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await Register(obj);
        localStorage.setItem('email',response.data.username);
        localStorage.setItem('uid',response.data.uid);
        return fulfillWithValue(response);
    } 
    catch (err) {
        const result = err.response.data;
        return rejectWithValue(result);
    }

});

export const resendEmail = createAsyncThunk('signup/resend email',async (obj)=>{
    const response = await ResentActivationEmail(obj);
    return response;
})

export const VerifyAccountAsync = createAsyncThunk('signup/verify-account', async (obj, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await VerifyAccount(obj);
        return fulfillWithValue(response);
    }
    catch (err) {
        const result = err;
        return rejectWithValue(result);
    }
})


const initialState = {
    Response: {},
    verifyStatus: 'idle',
    verifyAccountResponse: {},
    values: {
        firstName: '',
        lastName: '',
        email: '',
        birthYear: '',
        password:'',
        authRedirectURL: 'localhost:8000'
    }
};

export const SignUpSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setValues: (state, action) => {
            state.values.firstName = action.payload?.firstName;
            state.values.lastName = action.payload?.lastName;
            state.values.email = action.payload?.email;
            state.values.birthYear = action.payload?.birthYear;
            state.values.authRedirectURL = 'localhost:8000';
        },
        setPasswordValue : (state,action) =>{
            state.values.password = action.payload;
        }
    },
    extraReducers: {
        [SignUpAsync.pending]: (state) => {
            state.status = 'loading';
        },
        [SignUpAsync.fulfilled]: (state, action) => {
            state.status = 'idle';
            state.Response = action.payload;
        },
        [SignUpAsync.rejected]: (state, action) => {
            state.status = 'failed';
            state.Response = action.payload;
            state.error = action.payload;
        },
        [VerifyAccountAsync.pending]: (state) => {
            state.verifyStatus = 'loading';
        },
        [VerifyAccountAsync.fulfilled]: (state, action) => {
            state.verifyStatus = 'idle';
            state.verifyAccountResponse = action.payload;
        },
        [VerifyAccountAsync.rejected]: (state, action) => {
            state.verifyStatus = 'failed';
            state.verifyAccountResponse = action.payload;
            state.error = action.payload;
        },

    },
});
export const { setValues,setPasswordValue } = SignUpSlice.actions;
export const selectSignUpResponse = (state) => state.signup.Response;
export const selectValues = (state) => state.signup.values;
export const selectSignupStatus = (state) => state.signup.status;
export const selectVerifyAccountResponse = (state) => state.signup.verifyAccountResponse;
export const selectVerifyStatus = (state) => state.signup.verifyStatus;
// export const selectStatus = (state) => state.login.status;
export default SignUpSlice.reducer;
