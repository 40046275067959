import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionListing } from '../../app-api/api';

export const fetchCollectionListAsync = createAsyncThunk(
  'collection/collectionListing',
  async (obj) => {
    const response = await CollectionListing(obj);
    return response;
  }
);
const initialState = {
  List: [],
};

export const CollectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCollectionListAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCollectionListAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.List = action.payload.Collections;
    },
    [fetchCollectionListAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default CollectionSlice.reducer;
export const selectList = (state) => state?.collection?.List;
// export const {setAttachments,setYear,setMonth,setDate} = AddMemorySlice.actions;
// export const selectAttachments = (state)=> state.addMemory.Attachments;
// export const selectMemoryDate = (state)=> state.addMemory.Data.memory_date;
