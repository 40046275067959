import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTags, FriendsList } from '../../app-api/api';

export const fetchFriendList = createAsyncThunk(
  'add memory/fetch friend list',
  async (obj) => {
    const response = await FriendsList(obj);
    return response;
  }
);
export const fetchTagsAsync = createAsyncThunk(
  'add memory/fetch Tags list',
  async (obj) => {
    const response = await fetchTags(obj);
    return response;
  }
);
const initialState = {
  ListData: [],
  TagList: [],
  memoryUid: [],
  groupId: [],
  whoElseMemoryUid: [],
  Data: {
    title: '',
    memory_date: {
      year: '',
      month: '',
      date: '',
    },
    files: [],
    location: {
      description: '',
      reference: '',
    },
    share_option: 'custom',
    action: 'publish',
    description: '',
    collection: '',
    who_can_see_memory_uids: '',
    who_can_see_memory_group_ids: '',
    who_else_was_there_uids: '',
    memory_tags_text: [],
    is_comment_allowed: 1,
  },
};

export const AddMemorySlice = createSlice({
  name: 'addMemory',
  initialState,
  reducers: {
    setAttachments: (state, action) => {
           if (action.payload !== null) state.Data.files.push(action.payload);
    },
    setYear: (state, action) => {
      state.Data.memory_date.year = action.payload;
    },
    setMonth: (state, action) => {
      state.Data.memory_date.month = action.payload;
    },
    setDate: (state, action) => {
      state.Data.memory_date.memory_date = action.payload;
    },
    setAllowComment: (state, action) => {
      state.Data.is_comment_allowed = action.payload;
    },
    setMemoryTags: (state, action) => {
      state.Data.memory_tags_text = action.payload;
    },
    setMemoryTitle: (state, action) => {
      state.Data.title = action.payload;
    },
    setLocation: (state, action) => {
      state.Data.location.description = action.payload;
    },
    setDescription: (state, action) => {
      state.Data.description = action.payload;
    },
    setMemoryUids: (state, action) => {
      state.Data.who_can_see_memory_uids = action.payload;
    },
    setGroupids: (state, action) => {
      state.Data.who_can_see_memory_group_ids = action.payload;
    },

    setWhoElseWasThere: (state, action) => {
      state.Data.who_else_was_there_uids = action.payload;
    },

    setCollection: (state, action) => {
      if (action.payload.selected)
        state.Data.collection_tid += action.payload.tid + ',';
      else {
        state.Data.collection_tid = state.Data.collection_tid?.replace(
          action.payload.tid + ',',
          ''
        );
      }
    },
    DeleteAttachment: (state, action) => {
      state.Data.files = state.Data.files.filter((item) => {
        return item.fid !== action.payload;
      });
    },
  },
  extraReducers: {
    [fetchFriendList.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFriendList.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.ListData = action.payload;
    },
    [fetchFriendList.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchTagsAsync.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchTagsAsync.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.TagList = action.payload;
    },
    [fetchTagsAsync.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default AddMemorySlice.reducer;
export const {
  setAttachments,
  setYear,
  DeleteAttachment,
  setMonth,
  setMemoryTags,
  setMemoryUids,
  setCollection,
  setGroupids,
  setWhoElseWasThere,
  setDate,
  setDescription,
  setAllowComment,
  setMemoryTitle,
  setLocation,
} = AddMemorySlice.actions;
export const selectAttachments = (state) => state.addMemory.Data.files;
export const selectMemoryDate = (state) => state.addMemory.Data.memory_date;
export const selectListData = (state) => state.addMemory.ListData;
export const selectTitle = (state) => state.addMemory.Data.title;
export const selectMemoryUids = (state) =>
  state.addMemory.Data.who_can_see_memory_uids;
export const selectGroupUids = (state) =>
  state.addMemory.Data.who_can_see_memory_group_ids;
export const selectCollection = (state) => state.addMemory.Data.collection_tid;
export const selectMemoryData = (state) => state.addMemory.Data;
export const selectTags = (state) => state.addMemory.TagList;