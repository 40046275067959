// Import Lora font
import '@fontsource/lora/500.css';
import '@fontsource/lora/600.css';

// Import Inter Font
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

// Import CSS Reset from https://meyerweb.com/eric/tools/css/reset/
import './src/styles/reset.css';

// Wrap App with Redux Store Provider
import wrapWithProvider from './src/app/wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
